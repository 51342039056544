import React from 'react'
import { motion } from 'framer-motion'

function Skills({skills}) {
    // const mySkills = ['HTML', 'CSS','Javascript','React.JS','Tailwind','Figma', 'Responsive Design', '& More' ]
    const fadeInAnimationVariant = {
        initial: {
            opacity: 0,
            y: 100,
        },
        animate: (index) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: 0.05 * index
            }
        })
    }
  return (
    <div className='max-w-[320px]  mx-auto'>
        <ul className='flex flex-wrap justify-center lg:justify-start gap-1 lg:gap-2 text-lg text-[#232323]'>
            {
                skills.map((skill,index) => {
                    return (
                        <motion.li
                        key={index}
                        variants={fadeInAnimationVariant}
                        initial="initial"
                        whileInView="animate"
                        viewport={{
                            once: true,
                        }}
                        custom={index}
                         className=' bg-[#232323] tracking-wider text-[12px] text-white  rounded-[4px] px-2 py-.5'>{skill}
                        </motion.li>
                    )
                })
            }
        {/* <motion.li
        variants={fadeInAnimationVariant}
        initial="initial"
        whileInView="animate"
        viewport={{
            once: true,
        }}
        // custom={index}
         className='bg-white border border-black/10 rounded-xl px-5 py-3'>React
        </motion.li> */}
        </ul>

    </div>
  )
}

export default Skills