import React, { useRef } from 'react'
import Reveal from '../utils/Reveal'
import { FaPhoneSquareAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaSquareXTwitter } from "react-icons/fa6";
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast';





function Contact() {

    const form = useRef(null)

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
          .sendForm('service_7oplxxh', 'template_1a58rz2', form.current, {
            publicKey: 'njqf7joJuJEWsgvLi',
          })
          .then(
            () => {
              toast.success('Email sent, Thank you!')
            },
            (error) => {
              console.log('FAILED...', error.text);
              toast.error('Email failed to send, Please try again.');
            },
          );
      }

  return (
    <div id="contact" className='py-[90px] bg-[#232323]'>
          <div className='max-w-[1520px] py-[40px] px-8 mx-auto lg:gap-y-0 gap-y-6 lg:items-start'>
        <div className='flex flex-col lg:flex-row justify-center gap-x-[40px]'>
            <div className='flex flex-col items-center lg:items-start gap-y-[20px]'>
            <Reveal color="white" >
              <h6 className='anton-regular xl:text-left text-center text-[32px] lg:text-[40px] xl:text-[52px] max-w-[680px] font-bold text-[#ffffff] anton-regular'>Get in touch</h6>
            </Reveal>
                <Reveal color="white">
                    <p className='max-w-[580px] lg:text-left text-center text-[#ffffff]  text-[20px] tracking-wide leading-8'>
                    Feel free to reach out to me for job opportunities or employment inquiries. I'm open to discussing potential roles and collaborations.</p>
                </Reveal>
                <div className='hidden lg:flex flex-row items-center gap-x-4'>
                <div className='w-[50px] h-[50px] scale-95 hover:scale-100 cursor-pointer opacity-90 hover:opacity-100 flex flex-col items-center justify-center transition-all duration-200 rounded-full'>
                    <FaPhoneSquareAlt fill='white' size="30"/>
                </div>
                    <div className='flex flex-col items-start gap-y-1'>
                    <p className='max-w-[580px] text-[#ffffff]  text-[14px] tracking-wide leading-8'>Phone</p>
                    <p className='max-w-[580px] text-[#ffffff] anton-regular  text-[20px] tracking-wide leading-8'>(336)-406-8860</p>
                    </div>
                </div>

                <div className='hidden lg:flex flex-row items-center gap-x-4 my-4'>
                <div className='w-[50px] h-[50px] scale-95 hover:scale-100 cursor-pointer opacity-90 hover:opacity-100 flex flex-col items-center justify-center transition-all duration-200 rounded-full'>
                    <FaSquareXTwitter fill='white' size="30"/>
                </div>
                    <div className='flex flex-col items-start gap-y-1'>
                    <p className='max-w-[580px] text-[#ffffff]  text-[14px] tracking-wide leading-8'>Twitter (x)</p>
                    <a href="https://x.com/GhostyTheDev" target='_blank' className='max-w-[580px] text-[#ffffff] anton-regular  text-[20px] tracking-wide underline leading-8'>@GhostyTheDev</a>
                    </div>
                </div>

                <div className='hidden lg:flex flex-row items-center gap-x-4'>
                <div className='w-[50px] h-[50px] scale-95 hover:scale-100 cursor-pointer opacity-90 hover:opacity-100 flex flex-col items-center justify-center transition-all duration-200 rounded-full'>
                    <MdEmail fill='white' size="30"/>
                </div>
                    <div className='flex flex-col items-start gap-y-1'>
                    <p className='max-w-[580px] text-[#ffffff]  text-[14px] tracking-wide leading-8'>Email</p>
                    <p className='max-w-[580px] text-[#ffffff] anton-regular  text-[20px] tracking-wide leading-8'>WilliamRBadgett@hotmail.com</p>
                    </div>
                </div>

            </div>
            <div class="bg-white mx-auto md:mx-0 mt-6 p-6 max-w-[460px] h-fit rounded-[4px]">
                    <p class="text-[30px] anton-regular font-semibold text-[#232323]">Send a message</p>

                    <form ref={form} onSubmit={sendEmail} class="mt-8 space-y-4">
                        <input required name='name' type='text' placeholder='Name'
                            class="w-full border border-[#232323] rounded-lg py-3 px-4 text-[#232323] text-sm outline-[#232323]" />
                        <input required name='email' type='email' placeholder='Email'
                            class="w-full border border-[#232323] rounded-lg py-3 px-4 text-[#232323] text-sm outline-[#232323]" />
                        <input name='subject' type='text' placeholder='Subject'
                            class="w-full border border-[#232323] rounded-lg py-3 px-4 text-[#232323] text-sm outline-[#232323]" />
                        <textarea required name='message' placeholder='Message' rows="6"
                            class="w-full border border-[#232323] rounded-lg px-4 text-[#232323] text-sm pt-3 outline-[#232323]"></textarea>
                        <button type='submit'
                            class="text-white bg-[#232323] anton-regular tracking-wide rounded-lg text-sm px-4 py-3 flex items-center justify-center w-full !mt-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill='#fff' class="mr-2" viewBox="0 0 548.244 548.244">
                                <path fill-rule="evenodd" d="M392.19 156.054 211.268 281.667 22.032 218.58C8.823 214.168-.076 201.775 0 187.852c.077-13.923 9.078-26.24 22.338-30.498L506.15 1.549c11.5-3.697 24.123-.663 32.666 7.88 8.542 8.543 11.577 21.165 7.879 32.666L390.89 525.906c-4.258 13.26-16.575 22.261-30.498 22.338-13.923.076-26.316-8.823-30.728-22.032l-63.393-190.153z" clip-rule="evenodd" data-original="#000000" />
                            </svg>
                            Send Message
                        </button>
                    </form>
                </div>
        </div>
        
  </div>

    </div>
  )
}

export default Contact