import React, { useRef, useState } from 'react'
import { motion } from 'framer-motion'
import Reveal from '../utils/Reveal'

function Navbar() {
    const [position, setPosition] = useState({
        left: 0,
        width: 0,
        opacity: 0,
    })
    const ref = useRef(null)
  return (
    // Nav Container
    <div className='w-full py-[10px]'>
        {/* Nav Desktop Content */} 
        {/* Three Split Layout */}
        <div className='flex flex-row items-center justify-between mx-auto w-full max-w-[1524px] px-4'>
            {/* Logo */}
            <div className='hidden md:flex flex-row items-center gap-x-2'>
                {/* <div className='bg-[#232323] w-[60px] h-[60px] rounded-full'></div> */}
                <div className='flex flex-col items-start anton-regular uppercase tracking-wider'>
                    <p className='text-[#232323] font-semibold text-[24px] mb-[-.4em]'>William</p>
                    <p className='text-[#232323] font-semibold text-[24px]'>Badgett</p>
                </div>
            </div>
            {/* Links */}
            <SlideTabs/>
            {/* <div className='flex relative border-2 border-black rounded-full bg-white  w-fit p-1 anton-regular uppercase tracking-wider flex-row '>
            <a  className='text-[20px] relative z-10 cursor-pointer px-3 py-1.5 mix-blend-difference  text-white font-semibold'>About</a>
            <a  className='text-[20px] relative z-10 cursor-pointer px-3 py-1.5 mix-blend-difference  text-white font-semibold'>Portfolio</a>
            <a  className='text-[20px] relative z-10 cursor-pointer px-3 py-1.5 mix-blend-difference  text-white font-semibold'>Skills</a>
            <a  className='text-[20px] relative z-10 cursor-pointer px-3 py-1.5 mix-blend-difference  text-white font-semibold'>Contact</a>
            <motion.div
            animate={
            position   
            }
             className='absolute z-0 h-full rounded-full bg-black ' />
            </div> */}
            {/* Button */}
            <button className='px-6 hidden hover:bg-[#232323] hover:text-white md:block scale-95 hover:scale-100 cursor-pointer opacity-90 hover:opacity-100 transition-all duration-200 anton-regular uppercase tracking-wider py-2 rounded-md border border-[#232323] font-semibold'>Resume</button>
        </div>
    </div>
  )
}

export default Navbar



// export const SlideTabsExample = () => {
//     return (
//       <div className="bg-neutral-100 py-20">
//         <SlideTabs />
//       </div>
//     );
//   };
  
  const SlideTabs = () => {
    const [position, setPosition] = useState({
      left: 0,
      width: 0,
      opacity: 0,
    });
  
    return (
      <ul
        onMouseLeave={() => {
          setPosition((pv) => ({
            ...pv,
            opacity: 0,
          }));
        }}
        className="relative mx-auto flex w-fit rounded-[6px] border border-black bg-[#F3F3F3] p-1"
      >
        <Tab linkTo={'#about'} setPosition={setPosition}>Skills</Tab>
        <Tab linkTo={'#portfolio'} setPosition={setPosition}>Portfolio</Tab>
        <Tab linkTo={'#contact'} setPosition={setPosition}>Contact</Tab>
  
        <Cursor position={position} />
      </ul>
    );
  };
  
  const Tab = ({ children, setPosition, linkTo }) => {
    const ref = useRef(null);
  
    return (
      <a
      href={linkTo}
        ref={ref}
        onMouseEnter={() => {
          if (!ref?.current) return;
  
          const { width } = ref.current.getBoundingClientRect();
  
          setPosition({
            left: ref.current.offsetLeft,
            width,
            opacity: 1,
          });
        }}
        className="relative anton-regular z-10 block cursor-pointer px-3  uppercase text-[#232323] hover:text-white md:px-5 py-1 md:text-base"
      >
        {children}
      </a>
    );
  };
  
  const Cursor = ({ position }) => {
    return (
      <motion.li
        animate={{
          ...position,
        }}
        className="absolute z-0 h-[32px] rounded-[6px]
         bg-[#232323] "
      />
    );
  };
//   bg-gradient-to-r from-[#A870E3] via-[#DE6A85] to-[#F2AC5D]