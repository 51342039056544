import { useMotionValue, motion, useSpring, useTransform } from "framer-motion";
import React, { useRef } from "react";
import { FiArrowRight } from "react-icons/fi";
import Reveal from "../utils/Reveal";

export const HoverImageLinks = () => {


const projects = [
  {
    heading: 'Watchdogs',
    subheading: 'In my work on the Watchdogs app, I designed and developed the front end to provide a seamless experience for tracking all your assets from a single platform. Emphasizing user-friendly navigation and intuitive design, I simplified the process and enhanced accessibility.',
    imgSrc: '/images/watchdogs-showcase-2.png',
    href: 'https://watchdogs-chi.vercel.app/'
  },
  {
    heading: 'Bankmen',
    subheading: "For the Bankmen NFT project, I led the front-end development of a dynamic application with lending, borrowing, and raffle features. Working with a skilled team, I created a polished, user-friendly interface that played a key role in the project's success, generating over $800k in profit. My focus was on delivering a seamless user experience for this large-scale NFT platform.",
    imgSrc: '/images/bankmen-showcase-2.png',
    href: 'https://bankmen.finance/'
  },
  {
    heading: 'Cincinnati Krav Maga',
    subheading: "For Cincinnati Krav Maga, I developed a dynamic and efficient website to streamline client acquisition and enhance online visibility. The site is optimized for search engines to improve Google rankings and features intuitive navigation that showcases their classes and services effectively, making it easier for potential clients to learn about and engage with the business.",
    imgSrc: '/images/kravmaga-showcase.png',
    href: 'https://cincinnatikravmaga.com/'
  },
  {
    heading: 'Hidden',
    subheading: "For the Hidden AIO Bot project, I developed the front end of a complex 8-page UI. My role involved creating a responsive and intuitive interface that streamlined user interactions and supported the bot's multifaceted functionalities.",
    imgSrc: '/images/hidden-showcase.png',
    href: 'https://hidden-aio.vercel.app/'
  }
]
  return (
    <section id="portfolio" className="bg-[#232323] p-4 md:p-8">
      <div className="mx-auto max-w-[1520px] px-6">

      <div className='w-full justify-center xl:justify-between flex items-center my-12  flex-col xl:flex-row'>
            <Reveal color="white">
              <h6 className='anton-regular xl:text-left text-center text-[32px] lg:text-[40px] xl:text-[52px] max-w-[680px] font-bold text-[#F3F3F3] mb-4 anton-regular'>Projects that helps users, One app at a time.</h6>
            </Reveal>
            <Reveal color="white">
              <p className='xl:text-right text-center text-[16px] text-[#F3F3F3] 2xl:text-[18px] max-w-[590px] font-semibold tracking-widest anton-regular'>As a Junior Front-End Developer and UI/UX Designer, I’ve had the opportunity to work on a diverse array of projects for clients around the globe. My passion lies in crafting intuitive and engaging digital experiences tailored to a wide range of needs and industries.</p>
            </Reveal>
        </div>

        {
          projects.map((project, index) => {
            return (
              <Link
              heading={project.heading}
              subheading={project.subheading}
              imgSrc={project.imgSrc}
              href={project.href}
              ></Link>
            )
          })
        }

        {/* <Link
          heading="Watchdogs"
          subheading="
          In my work on the Watchdogs app, I designed and developed the front end to provide a seamless experience for tracking all your assets from a single platform. Emphasizing user-friendly navigation and intuitive design, I simplified the process and enhanced accessibility."
          imgSrc="/images/watchdogs-showcase-2.png"
          href="#"
        />

        <Link
          heading="Bankmen"
          subheading="For the Bankmen NFT project, I led the front-end development of a dynamic application with lending, borrowing, and raffle features. Working with a skilled team, I created a polished, user-friendly interface that played a key role in the project's success, generating over $800k in profit. My focus was on delivering a seamless user experience for this large-scale NFT platform."
          imgSrc="/images/bankmen-showcase-2.png"
          href="#"
        />

        <Link
          heading="Dailydraft"
          subheading="For Dailydraft Sports, I designed and developed the UI and front end of the platform, focusing on delivering an engaging and intuitive experience for users to select teams and place bets on their outcomes. My work ensured a smooth and visually appealing interface that simplifies the betting process, making it easy for users to navigate and enjoy their sports betting experience in the crypto space."
          imgSrc="/images/dailydraft-showcase-2.png"
          href="#"
        />

        <Link
          heading="Hidden"
          subheading="
          For the Hidden AIO Bot project, I developed the front end of a complex 8-page UI. My role involved creating a responsive and intuitive interface that streamlined user interactions and supported the bot's multifaceted functionalities.
          "
          imgSrc="/images/hidden-showcase.png"
          href="#"
        /> */}

      </div>
    </section>
  );
};

const Link = ({ heading, imgSrc, subheading, href }) => {

  

  const fadeInAnimationVariant = {
    initial: {
        opacity: 0,
        y: 100,
    },
    animate: (index) => ({
        opacity: 1,
        y: 0,
        transition: {
            delay: 0.05 * index
        }
    })
}
  const projects = {
    Watchdogs: '#E0FF6F',
    // Dailydraft: '#52BE70',
    Bankmen: '#01A2FF',
    Hidden: '#4DE2AA',
    'Cincinnati Krav Maga': '#296BCC'
  }
  const color = projects[heading];
  const ref = useRef(null);

  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const mouseXSpring = useSpring(x);
  const mouseYSpring = useSpring(y);

  const top = useTransform(mouseYSpring, [0.5, -0.5], ["40%", "60%"]);
  const left = useTransform(mouseXSpring, [0.5, -0.5], ["60%", "70%"]);

  const handleMouseMove = (e) => {
    const rect = ref.current.getBoundingClientRect();

    const width = rect.width;
    const height = rect.height;

    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    const xPct = mouseX / width - 0.5;
    const yPct = mouseY / height - 0.5;

    x.set(xPct);
    y.set(yPct);
  };
  

  return (
    <motion.a

    target="_blank"
      href={href}
      ref={ref}
      onMouseMove={handleMouseMove}
      initial="initial"
      whileHover="whileHover"
      variants={fadeInAnimationVariant}
      whileInView="animate"
      viewport={{
          once: true,
      }}
      className="group my-6 relative flex items-center justify-between border-b-2 border-neutral-700 py-4 transition-colors duration-500 hover:border-neutral-50 md:py-8"
    >
      <div>
        <motion.span
          variants={{
            initial: { x: 0 },
            whileHover: { x: -16 },
          }}
          transition={{
            type: "spring",
            staggerChildren: 0.075,
            delayChildren: 0.25,
          }}
          className="relative z-10 block text-4xl font-bold text-neutral-500 transition-colors duration-500 group-hover:text-neutral-50 md:text-6xl"
        >
          {heading.split("").map((l, i) => (
            <motion.span
              variants={{
                initial: { x: 0, color: '#F3F3F3' },
                whileHover: { x: 16, color },
              }}
              transition={{ type: "easeInOut" }}
              className="inline-block anton-regular"
              key={i}
            >
              {l}
            </motion.span>
          ))}
        </motion.span>
        <span className="relative z-10 mt-2 block text-base text-neutral-500 transition-colors duration-500 group-hover:text-neutral-50">
          {subheading}
        </span>
      </div>

      <motion.img
        style={{
          top,
          left,
          translateX: "-50%",
          translateY: "-50%",
        }}
        variants={{
          initial: { scale: 0, rotate: "-1.5deg" },
          whileHover: { scale: 1, rotate: "1.5deg" },
        }}
        transition={{ type: "easeInOut" }}
        src={imgSrc}
        className="absolute  z-20 w-[60%] h-auto  rounded-lg object-fill md:h-48 md:w-64"
        alt={`Image representing a link for ${heading}`}
      />

      <motion.div
        variants={{
          initial: {
            x: "25%",
            opacity: 0,
          },
          whileHover: {
            x: "0%",
            opacity: 1,
          },
        }}
        transition={{ type: "spring" }}
        className="relative z-10 p-4"
      >
        <FiArrowRight className="text-5xl text-neutral-50" />
      </motion.div>
    </motion.a>
  );
};