import About from "./components/About";
import Contact from "./components/Contact";
import Header from "./components/Header";
import { HoverImageLinks } from "./components/HoverImageLinks";
import Marquee from "./components/Marquee";
import Navbar from "./components/Navbar";
import Portfolio from "./components/Portfolio";
import Skills from "./components/Skills";
import toast, { Toaster } from 'react-hot-toast';


export default function App() {
  return (
    <div>
    <Navbar/>
    <Toaster/>
    <Header/>
    {/* <Marquee/> */}
    <HoverImageLinks/>
    <About/>
    <Contact/>
    {/* <Skills/> */}
    </div>
  )
}