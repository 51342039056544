import React from 'react'
import Reveal from '../utils/Reveal'
import Skills from './Skills'

function About() {
  return (    
  <div id="about" className='max-w-[1520px] py-[80px] px-8 mx-auto lg:gap-y-0 gap-y-6 lg:items-start'>
        <div className='flex flex-col lg:flex-row justify-center gap-y-[80px] gap-x-[80px]'>
            <div className='flex flex-col items-center lg:items-start gap-y-[40px]'>
            <Reveal >
              <h6 className='anton-regular xl:text-left text-center text-[32px] lg:text-[40px] xl:text-[52px] max-w-[680px] font-bold text-[#232323] anton-regular'>About me.</h6>
            </Reveal>
                <Reveal>
                    <p className='max-w-[580px] text-[#232323] lg:text-left text-center  text-[20px] tracking-wide leading-8'>
                    Hi! I’m William, a front-end developer and UI/UX designer with nearly 6 years of experience. I’m passionate about creating intuitive, engaging user interfaces and have a knack for blending design with functionality.</p>
                </Reveal>
                <Reveal>
                    <p className='max-w-[580px] text-[#232323] lg:text-left text-center  text-[20px] tracking-wide leading-8'>
                    When I’m not coding, I’m spending time with my 3-year-old daughter and embracing my love for learning. If you have a project where design and development intersect, I’d love to connect and explore how we can work together!
                    </p>
                </Reveal>
            </div>
            <div className='flex flex-col text-centerr lg:items-start'>
                <h6 className='anton-regular lg:text-left text-center text-[28px] mb-4'>My Skillset</h6>
                <Skills skills={['HTML', 'CSS','Javascript','React.JS','Tailwind','Figma', 'Responsive Design', '& More']}/>

                <h6 className='anton-regular lg:text-left text-center text-[28px] my-4'>Passion To Learn</h6>
                <Skills skills={ ['TypeScript', 'Node', 'Express','Python','SQL']}/>
            </div>
        </div>
  </div>
  )
}

export default About