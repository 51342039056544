import React from 'react'
import { useEffect, useRef } from 'react'
import { motion, useInView, useAnimation } from 'framer-motion'

function Reveal({children, color='#232323'}) {
    const ref = useRef(null)
    const mainControls = useAnimation()
    const slideControls = useAnimation()
    const isInView = useInView(ref, {once: true})
    useEffect(() => {
        if(isInView) {
            mainControls.start('visible')
            slideControls.start('visible')
        }
    }, [isInView])
  return (
    <div ref={ref} className='w-fit relative '>
        <motion.div
        variants={{
            hidden: {
                opacity: 0,
                y: 15
            },
            visible: {
                opacity: 1,
                y: 0
            }
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 0.5, delay: 0.15}}
        >{children}</motion.div>
        {/* Add slide div */}
        <motion.div
        variants={{
            hidden: {left:0},
            visible: {left: '100%'}
        }}
        initial="hidden"
        animate={slideControls}
        transition={{duration: .3, ease: "easeIn"}}
        style={{
            position: 'absolute',
            top: 4,
            bottom: 4,
            left: 0,
            right: 0,
            background: color,
            zIndex:20
        }}
        ></motion.div>
    </div>
  )
}

export default Reveal