import React from 'react'
import Reveal from '../utils/Reveal'
import { FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaSquarePhone } from "react-icons/fa6";

function Header() {
  return (
    <div className='max-w-[1520px] py-[60px] md:py-[120px] px-8 mx-auto flex justify-center lg:justify-between flex-col lg:flex-row items-center lg:gap-y-0 gap-y-6 lg:items-start'>
        <div className='flex flex-col items-center lg:items-start'>
            <Reveal>
            <p className=' text-[16px] 2xl:text-[20px] text-[#232323] font-light mb-4'>Front-end developer.</p>
            </Reveal>
            <Reveal>
            <h1 className='lg:text-left text-center text-[32px] lg:text-[40px] 2xl:text-[52px] max-w-[680px] font-bold text-[#232323] mb-4 anton-regular'>Hi, I’m William. I create and build interfaces that engage and motivate users.</h1>
            </Reveal>
            <Reveal>
            <p className=' lg:text-left text-center text-[16px] 2xl:text-[20px] max-w-[530px] font-semibold tracking-widest anton-regular'>Junior Front-End Developer & UI/UX Designer passionate about creating intuitive and engaging digital experiences.</p>
            </Reveal>
            <Reveal>
                <div className='flex flex-row gap-x-4 mt-8'>
                <button class="px-6 py-2 rounded-md scale-95 hover:scale-100 cursor-pointer opacity-90 hover:opacity-100 text-white bg-[#232323] font-semibold anton-regular uppercase tracking-wider  transition-all duration-200">
                    View Portfolio
                </button>

                <button className='px-6 scale-95 hover:scale-100 cursor-pointer opacity-90 hover:opacity-100 py-2 rounded-md  text-[#232323] font-semibold anton-regular tracking-wider uppercase transition-all duration-200'>Reach Out</button>
                </div>
            </Reveal>
            {/* Social Links */}
            <Reveal>
                <div className='flex flex-row items-center gap-x-2 mt-8'>
                    <a href="https://www.linkedin.com/in/william-badgett-76a99827b/" target='_blank' className='w-[50px] h-[50px] flex flex-col items-center justify-center scale-95 hover:scale-100 cursor-pointer opacity-90 hover:opacity-100 transition-all duration-200  rounded-full'><FaLinkedin size="30"/></a>
                    <a href="https://x.com/GhostyTheDev" target='_blank' className='w-[50px] h-[50px] flex flex-col items-center justify-center scale-95 hover:scale-100 cursor-pointer opacity-90 hover:opacity-100 transition-all duration-200  rounded-full'><FaSquareXTwitter size="30"/></a>
                    <a href="#contact" className='w-[50px] h-[50px] flex flex-col items-center justify-center scale-95 hover:scale-100 cursor-pointer opacity-90 hover:opacity-100 transition-all duration-200  rounded-full'><FaSquarePhone size="30"/></a>
                </div>
            </Reveal>
        </div>
        <div className='flex flex-col items-center lg:items-end'>
                <img className='w-[80%]' src="/images/william.png"/>
        </div>
    </div>
  )
}

export default Header